import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './list.css'; 
import CountUp from 'react-countup'; 

  const List = () => {
  const [installData, setInstallData] = useState([]);
  const [uninstallData, setUninstallData] = useState([]);
  const [filteredInstallData, setFilteredInstallData] = useState([]);
  const [filteredUninstallData, setFilteredUninstallData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [expandedInstallItem, setExpandedInstallItem] = useState(null);
  const [expandedUninstallItem, setExpandedUninstallItem] = useState(null);
  const [topInstallationCountries, setTopInstallationCountries] = useState([]);
  const [topUninstallationCountries, setTopUninstallationCountries] = useState([]);
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const installResponse = await axios.get('http://localhost:3001/m-install');
      const uninstallResponse = await axios.get('http://localhost:3001/m-uninstall');
      // const installResponse = await axios.get('https://brdash.supportplus.app/m-install');
      // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');
      // const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
      // const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

      setInstallData(installResponse.data);
      setUninstallData(uninstallResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    filterData();
  }, [installData, uninstallData, startDate, endDate]);

  // const filterData = () => {
  //   let filteredInstall = installData.filter(item => {
  //     const itemDate = new Date(item.m_DateTime);
  //     const start = startDate ? new Date(startDate) : null;
  //     const end = endDate ? new Date(endDate) : null;
  //     return (!start || itemDate >= start) && (!end || itemDate <= end);
  //   });

  //   filteredInstall.sort((a, b) => b.installCount - a.installCount);
  //   filteredInstall = filteredInstall.slice(0, 10).map(item => item.m_ProName);
  //   setFilteredInstallData(filteredInstall);

  //   let filteredUninstall = uninstallData.filter(item => {
  //     const itemDate = new Date(item.m_DateTime);
  //     const start = startDate ? new Date(startDate) : null;
  //     const end = endDate ? new Date(endDate) : null;
  //     return (!start || itemDate >= start) && (!end || itemDate <= end);
  //   });

  //   filteredUninstall.sort((a, b) => b.uninstallCount - a.uninstallCount);
  //   filteredUninstall = filteredUninstall.slice(0, 10).map(item => item.m_ProName);
  //   setFilteredUninstallData(filteredUninstall);

  //   // Fetch top installation and uninstallation countries
  //   const topInstallCountries = getTopCountries(installData, 'm_CountryName');
  //   setTopInstallationCountries(topInstallCountries);

  //   const topUninstallCountries = getTopCountries(uninstallData, 'm_CountryName');
  //   setTopUninstallationCountries(topUninstallCountries);
  // };

  // const toggleInstallAnimation = index => {
  //   setExpandedInstallItem(expandedInstallItem === index ? null : index);
  // };

  // const toggleUninstallAnimation = index => {
  //   setExpandedUninstallItem(expandedUninstallItem === index ? null : index);
  // };
  const filterData = () => {
    let uniqueFilteredInstall = [];
    let uniqueFilteredUninstall = [];
  
    // Filter and get unique top 10 installed products
    let filteredInstall = installData.filter(item => {
      const itemDate = new Date(item.m_DateTime);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (!start || itemDate >= start) && (!end || itemDate <= end);
    });
  
    filteredInstall.sort((a, b) => b.installCount - a.installCount);
    filteredInstall.forEach(item => {
      if (!uniqueFilteredInstall.includes(item.m_ProName) && uniqueFilteredInstall.length < 10) {
        uniqueFilteredInstall.push(item.m_ProName);
      }
    });
    setFilteredInstallData(uniqueFilteredInstall);
  
    // Filter and get unique top 10 uninstalled products
    let filteredUninstall = uninstallData.filter(item => {
      const itemDate = new Date(item.m_DateTime);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      return (!start || itemDate >= start) && (!end || itemDate <= end);
    });
  
    filteredUninstall.sort((a, b) => b.uninstallCount - a.uninstallCount);
    filteredUninstall.forEach(item => {
      if (!uniqueFilteredUninstall.includes(item.m_ProName) && uniqueFilteredUninstall.length < 10) {
        uniqueFilteredUninstall.push(item.m_ProName);
      }
    });
    setFilteredUninstallData(uniqueFilteredUninstall);
  
    // Fetch top installation and uninstallation countries
    const topInstallCountries = getTopCountries(installData, 'm_CountryName');
    setTopInstallationCountries(topInstallCountries);
  
    const topUninstallCountries = getTopCountries(uninstallData, 'm_CountryName');
    setTopUninstallationCountries(topUninstallCountries);
  };
  
  // Function to get top countries based on the provided data and key
  const getTopCountries = (data, key) => {
    const countriesMap = new Map();
    data.forEach(item => {
      if (countriesMap.has(item[key])) {
        countriesMap.set(item[key], countriesMap.get(item[key]) + 1);
      } else {
        countriesMap.set(item[key], 1);
      }
    });
    const sortedCountries = Array.from(countriesMap.entries()).sort((a, b) => b[1] - a[1]);
    return sortedCountries.slice(0, 10).map(country => country[0]);
  };
  const toggleInstallAnimation = index => {
    setExpandedInstallItem(expandedInstallItem === index ? null : index);
  };
  
  const toggleUninstallAnimation = index => {
    setExpandedUninstallItem(expandedUninstallItem === index ? null : index);
  };
  

  return (
    <div className='container'>
      
      <div className='row mt-5 pt-5'>
      <div className='col-lg-3'>
      <h6>Top 10 Most Installed Products</h6>
      <ul className="card-list">
  {filteredInstallData.map((item, index) => (
    <li key={index} className={`card ${expandedInstallItem === index ? 'expanded' : ''}`} onMouseEnter={() => toggleInstallAnimation(index)} onMouseLeave={() => toggleInstallAnimation(index)}> {/*eslint-disable-line*/}
      {item}
      {/* <span className={`arrow ${(expandedInstallItem === index && index % 2 === 0) ? 'up' : 'down'}`}>↑</span> */}
    </li>
  ))}
</ul>

      </div>
      <div className='col-lg-3'>
      <h6>Top 10 Most Uninstalled Products</h6>
      <ul className="card-list">
        {filteredUninstallData.map((item, index) => (
          <li key={index} className={`card ${expandedUninstallItem === index ? 'expanded' : ''}`} onMouseEnter={() => toggleUninstallAnimation(index)} onMouseLeave={() => toggleUninstallAnimation(index)}>
            {item} 
             {/* <span className={`arrow ${(expandedUninstallItem === index && index % 2 === 0) ? 'up' : 'down'}`}>↑</span> */}
          </li>
        ))}
      </ul>
      </div>
      <div className='col-lg-3'>
      <h6>Top 10 Most Installed Countries</h6>
      <ul className="card-list">
        {topInstallationCountries.map((country, index) => (
          <li key={index} className="card">
            {country}
          </li>
        ))}
      </ul>
      </div>
<div className='col-lg-3'>

      <h6>Top 10 Most Uninstalled Countries</h6>
      <ul className="card-list">
        {topUninstallationCountries.map((country, index) => (
          <li key={index} className="card">
            {country}
          </li>
        ))}
      </ul>
      </div>
          </div>
    </div>
  );
};

export default List;
