import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, FormControl, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Flags from 'react-flags-select';
import { getOsIcon, getBrowserIcon, getCountryFlag } from '../../util/util.js';
import CountUp from 'react-countup';
import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const UninstallData = () => {
  const [installDetails, setInstallDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [installData, setInstallData] = useState([]);
  const [productInstallationData, setProductInstallationData] = useState([]);
  const [uninstallData, setUninstallData] = useState([]);
  const [dateRange, setDateRange] = useState('week');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [filter, setFilter] = useState({
    countryName: '',
    os: '',
    city: '',
    productName: '',
  });
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filteredUninstallItemCount, setFilteredUninstallItemCount] = useState(0);

  useEffect(() => {
    fetchInstallDetails();
  }, []);

  useEffect(() => {
    calculateFilteredItemCount(); // Update filtered item count whenever filters change
  }, [filter, uninstallData]);


  useEffect(() => {
   fetchData();
  }, [dateRange , startDate , endDate]);

  const fetchInstallDetails = async () => {
    try {
      const response = await axios.get('https://brdash.supportplus.app/uninstall');
      // const response = await axios.get('https://brdash.supportplus.app/m-uninstall');
      // const response = await axios.get('https://dash.bitrecover.com/m-uninstall');
      setInstallDetails(response.data);
    } catch (error) {
      console.error('Error fetching install details:', error);
    }
  };


  const fetchData = async () => {
    try {
      // const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
      const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
      // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');
      // const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

      // setInstallData(installResponse.data);
      setUninstallData(uninstallResponse.data);
      // computeStatistics(installResponse.data, uninstallResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
const calculateFilteredItemCount = () => {

    const filteredUninstallItems = uninstallData.filter(item => {
      return (
        (filter.countryName ? item.cname.toLowerCase().includes(filter.countryName.toLowerCase()) : true) &&
        (filter.productName ? item.pname.toLowerCase().includes(filter.productName.toLowerCase()) : true)
      );
    });
    setFilteredUninstallItemCount(filteredUninstallItems.length);
  };


  useEffect(() => {
    generateData();
  }, [dateRange, startDate, endDate]);


  const filterDataByDateRange = (data, startDate, endDate) => {
    return data.filter(detail => {
        const detailDate = new Date(detail.datetime);
        // Convert detail date to UTC to align with API date
        const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
        return utcDetailDate >= startDate && utcDetailDate < endDate;
    }).length;
  };

  const generateData = () => {
    const getDateLabel = (date) => {
      const options = { month: 'short', weekday: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    };

    const data = [];
    let startUtcDate, endUtcDate;

    if (!startDate || !endDate) {
      const defaultStartDate = new Date(); 
      defaultStartDate.setDate(defaultStartDate.getDate() - 6); 
      startUtcDate = new Date(Date.UTC(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), defaultStartDate.getDate()));

      const defaultEndDate = new Date(); 
      defaultEndDate.setDate(defaultEndDate.getDate() + 1); 
      endUtcDate = new Date(Date.UTC(defaultEndDate.getFullYear(), defaultEndDate.getMonth(), defaultEndDate.getDate()));
    } else {
      startUtcDate = new Date(startDate);
      endUtcDate = new Date(endDate);
      endUtcDate.setDate(endUtcDate.getDate() + 1); 
    }

    for (let date = new Date(startUtcDate); date < endUtcDate; date.setDate(date.getDate() + 1)) {
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);

      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const utcNextDay = new Date(Date.UTC(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate()));

      const uninstallations = filterDataByDateRange(uninstallData, utcDate, utcNextDay);
      const dateLabel = getDateLabel(utcDate);
      const todayUninstallations = filterDataByDateRange(uninstallData, utcDate, new Date(utcDate.getTime() + (24 * 60 * 60 * 1000)));
      data.push({ date: dateLabel, uninstallations, todayUninstallations });
    }

    return data;
  };
const computeStatistics = (installations, uninstallations) => {
  ;

// Compute product installation data
const productInstallationMap = new Map();
installations.forEach(install => {
const productName = install.pname;
productInstallationMap.set(productName, (productInstallationMap.get(productName) || 0) + 1);
});
setProductInstallationData(Array.from(productInstallationMap).map(([productName, install]) => ({ productName, install })));



// Compute country uninstallation data
}


const getMostFrequentItem = (items) => {
  const countMap = new Map();
  items.forEach(item => {
    countMap.set(item, (countMap.get(item) || 0) + 1);
  });
  const sortedItems = Array.from(countMap.entries()).sort((a, b) => b[1] - a[1]);
  return sortedItems[0][0];
};




const handleStartDateChange = e => {
  setStartDate(e.target.value);
};

const handleEndDateChange = e => {
  setEndDate(e.target.value);
};


  const handleInputChange = (event, key) => {
    setFilter({ ...filter, [key]: event.target.value });
  };

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const sortItems = (a, b) => {
    const valA = typeof a[sortColumn] === 'string' ? a[sortColumn].toLowerCase() : a[sortColumn];
    const valB = typeof b[sortColumn] === 'string' ? b[sortColumn].toLowerCase() : b[sortColumn];

    if (sortDirection === 'asc') {
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    } else {
      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    }
  };

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  

  const filteredItems = installDetails.filter((item) => {
    return (
      (filter.countryName ? item.cname.toLowerCase().includes(filter.countryName.toLowerCase()) : true) &&
      (filter.productName ? item.pname.toLowerCase().includes(filter.productName.toLowerCase()) : true)
    );
  });

  const sortedItems = sortColumn ? filteredItems.sort(sortItems) : filteredItems;

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const sortArrow = (columnName) => {
    if (sortColumn === columnName) {
      return sortDirection === 'asc' ? <span>&uarr;</span> : <span>&darr;</span>;
    }
    return null;
  };

  const csvData = filteredItems.map(detail => ({
    ID: detail.id,
    IP: detail.ipaddress,
    Product: detail.pname,
    CountryName: detail.cname,
    Date_Time: new Date(detail.datetime).toLocaleString('en-US', { timeZone: 'UTC' }),
  }));

  const uninstallNumbers = () => {
    let totalUninstallations = 0;

    // Convert start and end dates to UTC
    const startUtcDate = startDate ? new Date(startDate + 'T00:00:00Z') : null;
    const endUtcDate = endDate ? new Date(endDate + 'T23:59:59Z') : null;

    for (let detail of uninstallData) {
        const detailDate = new Date(detail.datetime);

        // Adjust detail date to UTC
        const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));

        // Check if the detail date falls within the specified date range
        const isInRange = (!startUtcDate || utcDetailDate >= startUtcDate) &&
                          (!endUtcDate || utcDetailDate <= endUtcDate);

        if (isInRange) {
            totalUninstallations++;
        }
    }

    // Format the total uninstallations count
    const formattedUninstallations = totalUninstallations.toLocaleString('en-US');

    return formattedUninstallations;
};

const uninstallCount = uninstallNumbers();
// console.log("uninstallCount :" , uninstallCount)

const getUninstallationTrend = () => {
    const trendData = [];
    
    // Convert start and end dates to UTC
    const startUtcDate = startDate ? new Date(startDate + 'T00:00:00Z') : null;
    const endUtcDate = endDate ? new Date(endDate + 'T23:59:59Z') : null;

    let previousUninstallations = 0;

    for (let detail of uninstallData) {
        const detailDate = new Date(detail.datetime);

        // Adjust detail date to UTC
        const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));

        // Check if the detail date falls within the specified date range
        const isInRange = (!startUtcDate || utcDetailDate >= startUtcDate) &&
                          (!endUtcDate || utcDetailDate <= endUtcDate);

        if (isInRange) {
            trendData.push({
                date: utcDetailDate,
                type: detail.m_UninstallType // Assuming there's a property for uninstall type
            });

            previousUninstallations++;
        }
    }

    // Calculate the trend
    let trend = '→'; // Default is no change
    const currentUninstallations = trendData.length;
    if (currentUninstallations > previousUninstallations) {
        trend = '↑'; // Positive trend
    } else if (currentUninstallations < previousUninstallations) {
        trend = '↓'; // Negative trend
    }

    return { trendData, trend };
};

const uninstallTrends = getUninstallationTrend();


  return (
    <div className="container-fluid mt-5">
      <div className='row'>
  {/* <h1 className="text-center mb-4">Install Details</h1> */}
  <div>
  <div className=''>
        <label>
          Start Date:
          <input 
          type="date" 
          value={startDate} 
          onChange={handleStartDateChange} />
        </label>
        <label>
          End Date:
          <input 
          type="date" 
          value={endDate} 
          onChange={handleEndDateChange} />
        </label>
      </div>

        </div>

{/*         
        <div className='col-lg-2 mt-5'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold small mb-0 small'>Uninstalled</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={uninstallCount} 
                duration={3} 
                separator="," />
              </p>
             </div>
             </div>
             </div> */}


    
            <div className='col-lg-12 mt-5'>
    <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
        <ResponsiveContainer width="100%" height={350}>
        <BarChart
  data={generateData()}
  margin={{ top: 20, right: 30, left: 20, bottom: 10 }} 
>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="date" tick={{ fontSize: 12 }} angle={-45} textAnchor="end" interval={0} /> 
  <YAxis />
  <Tooltip />
  <Legend />
  <Bar dataKey="uninstallations" fill="#8884d8" style={{marginTop:'50px'}} />
</BarChart>

        </ResponsiveContainer>
        </div>
        <p className='fw-bold text-center'>Uninstalled Data</p>
        </div>





<div className='col-lg-3 mt-5'>
<DropdownButton
          title={`Show ${itemsPerPage} entries`}
          variant="primary"
          id="dropdown-basic-button"
        >
          <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
          <Dropdown.Item onClick={() => handleItemsPerPageChange(25)}>25</Dropdown.Item>
          <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>50</Dropdown.Item>
          <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>100</Dropdown.Item>
        </DropdownButton>
</div>
     
      <div className="col-lg-8 filters mb-3 ms-5 mt-5 ">
        <InputGroup>
        <div className="d-flex align-items-center">
          <span className="me-2">Filtered Item Count:</span>
          <span className="badge bg-primary">{filteredUninstallItemCount}</span>
        </div>
          <FormControl
            placeholder="Country Name"
            value={filter.countryName}
            onChange={(e) => handleInputChange(e, 'countryName')}
            className='ms-4'
            style={{borderRadius:'8px'}}
          />
          <FormControl
            placeholder="Product Name"
            value={filter.productName}
            onChange={(e) => handleInputChange(e, 'productName')}
            className='ms-4'
            style={{borderRadius:'8px'}}
          />
        </InputGroup>
      </div>

      <div class="table-responsive">
        <Table bordered hover className="small mt">
          <thead className="">
            <tr className="text-center">
              <th onClick={() => handleSort('id')}>ID {sortArrow('id')}</th>
              <th onClick={() => handleSort('ipaddress')}>IP {sortArrow('ipaddress')}</th>
              <th onClick={() => handleSort('pname')}>Product-Name {sortArrow('pname')}</th>
              <th onClick={() => handleSort('cname')}>CountryName {sortArrow('cname')}</th>
              <th onClick={() => handleSort('datetime')}>Date - Time {sortArrow('datetime')}</th>
            </tr>
          </thead>
           <tbody className='small'>
         {currentItems.map((detail, index) => (
            <tr key={index}>
              <td>{detail.id}</td>
              <td>{detail.ipaddress}</td>
              <td>{detail.pname}</td>
              <td>
                {/* {detail.m_CountryCode && detail.cname && (
                  <img src={`flags/${detail.m_CountryCode.toLowerCase()}.png`} alt={detail.cname} className="mr-1" />
                )} */}
                {detail.cname}
              </td>
              <td>{new Date(detail.datetime).toLocaleString('en-US', { timeZone: 'UTC' })}</td>
            </tr>
          ))}
        </tbody>
        </Table>
      </div>


      

      <div className="d-flex justify-content-between align-items-center mb-3">


        <div className="pagination">
          <Button
            variant="primary"
            className="mr-2"
            onClick={handlePreviousPage}
            disabled={currentPage <= 1}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Previous
          </Button>
          <span className="align-self-center mr-2">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="primary"
            onClick={handleNextPage}
            disabled={currentPage >= totalPages}
          >
            Next <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
        
      <div className="d-flex justify-content-end">
        <CSVLink data={csvData} filename={'filtered_install_details.csv'} className="btn btn-primary">
          Export to CSV
        </CSVLink>
      </div>
      
      </div>
     


      
      </div>
    </div>
  );
};

export default UninstallData;