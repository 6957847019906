import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
  });

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const login = () => setIsLoggedIn(true);
  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};



// import React, { createContext, useContext, useState, useEffect } from 'react';

// const AuthContext = createContext();

// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(() => {
//     return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
//   });

//   useEffect(() => {
//     localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
//   }, [isLoggedIn]);

//   const login = () => setIsLoggedIn(true);
//   const logout = () => {
//     // Call your logout API here
//     fetch('https://dash.bitrecover.com/logout', {
//       method: 'POST', // or 'GET', 'PUT', etc.
//       headers: {
//         'Content-Type': 'application/json',
//         // Include any necessary authentication headers, if required
//       },
//       // You may need to pass some data to the logout API if required
//       // body: JSON.stringify({ /* data */ }),
//     })
//     .then(response => {
//       // Handle response accordingly
//       if (response.ok) {
//         setIsLoggedIn(false);
//         localStorage.removeItem('isLoggedIn');
//         localStorage.removeItem('token');
//         localStorage.removeItem('user');
//         window.location.href = '/'; // Redirect to home page after successful logout
//       } else {
//         // Handle errors if any
//         console.error('Logout failed');
//       }
//     })
//     .catch(error => {
//       // Handle network errors
//       console.error('Network error:', error);
//     });
//   };

//   return (
//     <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
