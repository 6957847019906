import { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { FaHome, FaTools, FaTrashAlt, FaChartBar, FaList, FaSignOutAlt, FaArrowCircleLeft, FaMoon, FaImage } from 'react-icons/fa'; 
import { useAuth } from '../../Auth/AuthContext';
import sidebarImage from './side.jpg';
// import logo from './logo.webp';

const Sidebarr = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const { isLoggedIn, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const toggleImage = () => {
    setShowImage(!showImage);
  };




  const sidebarStyles = {
    backgroundColor: darkTheme ? '#0b2948' : 'rgb(249, 249, 249, 0.7)',
    color: darkTheme ? 'black' : 'black',
    backgroundImage: showImage ? `url(${sidebarImage})` : 'none',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'background-color 0.3s ease',
    overflowY: collapsed ? 'auto' : 'visible', 
  };

  return (
    <>
      {isLoggedIn && (
       
        <Sidebar className='pe-0' collapsed={collapsed} rootStyles={sidebarStyles} style={{height:'calc(100vh)'}}  >
          <Menu
            menuItemStyles={{
              button: {
                '&:hover': {
                  backgroundColor: darkTheme ? '#1f1f1f' : '#e6f2ff',
                  color: darkTheme ? '#fff' : '#13395e',
                },
              },
            }}
          >
          
                {/* <img src={logo} alt="Logo" style={{ height: "40px" }} /> */}
        
            <MenuItem icon={<FaHome />} component={<Link to="/home" />}>Home</MenuItem>
            <MenuItem icon={<FaTools />} component={<Link to="/install" />}>Install</MenuItem>
            <MenuItem icon={<FaTrashAlt />} component={<Link to="/uninstall" />}>Uninstall</MenuItem>
            <MenuItem icon={<FaChartBar />} component={<Link to="/analytics" />}>Report</MenuItem>
            {/* <MenuItem icon={<FaList />} component={<Link to="/list" />}>List</MenuItem> */}
          </Menu>

          <div style={{ marginTop: 'auto', position: 'absolute', bottom: '0', width: '100%' }}>
            <Menu>
              <MenuItem icon={<FaArrowCircleLeft />} onClick={toggleCollapse}>Collapse</MenuItem>
              <MenuItem icon={<FaMoon />} onClick={toggleTheme}>Dark Theme</MenuItem>
              <MenuItem icon={<FaImage />} onClick={toggleImage}>Image</MenuItem>
              <MenuItem icon={<FaSignOutAlt />} onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Sidebar>
        
      )}
    </>
  );
};

export default Sidebarr;
