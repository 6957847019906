import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Table, FormControl, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

const Home = () => {
  const [installData, setInstallData] = useState([]);
  const [uninstallData, setUninstallData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(10); 

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const data = generateData();
    setFilteredData(data);
  }, [installData, uninstallData, selectedStartDate, selectedEndDate, productSearchTerm, countrySearchTerm]);

  const fetchData = async () => {
    try {
      const installResponse = await axios.get('https://brdash.supportplus.app/install');
      const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
      // const installResponse = await axios.get('https://brdash.supportplus.app/m-install');
      // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');
      // const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
      // const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

      setInstallData(installResponse.data);
      setUninstallData(uninstallResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };


  const filterDataByDateRange = (data, startDate, endDate) => {
        return data.filter(detail => {
          const detailDate = new Date(detail.datetime);
          const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
          return utcDetailDate >= startDate && utcDetailDate < endDate;
        });
      };

  const generateData = () => {
        const data = [];
        const startDate = new Date(selectedStartDate);
        let endDate = new Date(selectedEndDate);
    
        switch ('') {
          case 'day':
            endDate.setDate(endDate.getDate() + 1);
            break;
          case 'week':
            startDate.setDate(startDate.getDate() - 6);
            endDate.setDate(endDate.getDate() + 1);
            break;
          case 'month':
            startDate.setDate(1);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            endDate.setDate(endDate.getDate() + 1);
            break;
          case 'year':
            startDate.setMonth(0);
            startDate.setDate(1);
            endDate.setMonth(11);
            endDate.setDate(31);
            endDate.setDate(endDate.getDate() + 1);
            break;
          default:
            endDate.setDate(endDate.getDate() + 1);
            break;
        }
    
        const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
        const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
    
        // Filter data based on selected date range
        const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
        const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);
    
        // Group installation data by product and country
        const groupedData = {};
        filteredInstallData.forEach(detail => {
          const { pname, cname } = detail;
          const key = `${pname}-${cname}`;
          if (!groupedData[key]) {
            groupedData[key] = { date: startDate.toLocaleDateString(), product: pname, country: cname, installations: 0, uninstallations: 0 };
          }
          groupedData[key].installations++;
        });
    
        // Update grouped data with uninstallations
        filteredUninstallData.forEach(detail => {
          const { pname, cname } = detail;
          const key = `${pname}-${cname}`;
          if (groupedData[key]) {
            groupedData[key].uninstallations++;
          }
        });
    
        // Transform grouped data into chart data format
        Object.keys(groupedData).forEach(key => {
          data.push(groupedData[key]);
        });
    
        return data;
      };
      
    
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleProductSearchTermChange = (event) => {
    setProductSearchTerm(event.target.value);
  };

  const handleCountrySearchTermChange = (event) => {
    setCountrySearchTerm(event.target.value);
  };

  
  const sortedData = () => {
    const sorted = [...filteredData];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  };

  // Filter data based on search terms
  const filteredDataBySearch = () => {
    return sortedData().filter(item =>
      item.product.toLowerCase().includes(productSearchTerm.toLowerCase()) &&
      item.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
    );
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Pagination logic
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredDataBySearch().slice(indexOfFirstData, indexOfLastData);
  const totalPages = Math.ceil(filteredDataBySearch().length / dataPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const csvData = [['Product', 'Country', 'Installations', 'Uninstallations'], ...currentData.map(item => [item.product, item.country, item.installations, item.uninstallations])];

  const totalInstallations = currentData.reduce((total, entry) => total + entry.installations, 0);
  const totalUninstallations = currentData.reduce((total, entry) => total + entry.uninstallations, 0);

  return (
//     <>
//       <div className='row'>
 
       
      

// <div className='col-md-5'>
//   {/* <div className="card shadow"> */}
//     <div className="card">
//       Date Range Filter
//     </div>
//     <div className="card-body">
//       <div className="form-group">
//         <label>Start Date:</label>
//         <DatePicker className="form-control ms-3" selected={selectedStartDate} onChange={handleStartDateChange} />
//       </div>
//       <div className="form-group">
//         <label>End Date:</label>
//         <DatePicker className="form-control ms-3 mt-3" selected={selectedEndDate} onChange={handleEndDateChange} />
//       </div>
//     </div>
//   {/* </div> */}
// </div>

//         <div className='col-md-5 ms-5 px-5'>
        
//             <p className="card">Search Filter</p>
//             <div className="card-body">
//               <div className="form-group">
//                 <label>Search by Product:</label>
//                 <input className="form-control" type="text" value={productSearchTerm} onChange={handleProductSearchTermChange} />
//               </div>
//               <div className="form-group">
//                 <label>Search by Country:</label>
//                 <input className="form-control" type="text" value={countrySearchTerm} onChange={handleCountrySearchTermChange} />
//               </div>
//             </div>
      
//         </div>
//       </div>


//       {/* <div className='col-lg-3 mt-5'>
// <DropdownButton
//           title={`Show ${itemsPerPage} entries`}
//           variant="primary"
//           id="dropdown-basic-button"
//         >
//           <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleItemsPerPageChange(25)}>25</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>50</Dropdown.Item>
//           <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>100</Dropdown.Item>
//         </DropdownButton>
// </div> */}
     
//       <div className='row'>
//         <div className='col'>
//           {/* <p className='fw-bold'>Data Table</p> */}
//           <table className='table table-striped'>
//             <thead>
//               <tr>
//                 <th onClick={() => handleSort('product')}>Product</th>
//                 <th onClick={() => handleSort('country')}>Country</th>
//                 <th onClick={() => handleSort('installations')}>Installations</th>
//                 <th onClick={() => handleSort('uninstallations')}>Uninstallations</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentData.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item.product}</td>
//                   <td>{item.country}</td>
//                   <td>{item.installations}</td>
//                   <td>{item.uninstallations}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       <div className='row'>
//         <div className='col'>
//           <p>Total Installations: {totalInstallations}</p>
//           <p>Total Uninstallations: {totalUninstallations}</p>
//         </div>
//       </div>

//       <div className='row'>
//         <div className='col'>
//           <div className="d-flex justify-content-between align-items-center mb-3">
//             <div className="">
//               <nav aria-label="Page navigation example">
//                 <ul className="pagination">
//                   <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                     <button className="page-link" onClick={() => handlePagination(currentPage - 1)}>Previous</button>
//                   </li>
//                   <li className={`page-item`}>
//                     <button className="page-link" disabled>{currentPage}</button>
//                   </li>
//                   <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                     <button className="page-link" onClick={() => handlePagination(currentPage + 1)}>Next</button>
//                   </li>
//                 </ul>
//                 </nav>
//             </div>
//             <div className="d-flex justify-content-end">
//               <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary">Export to CSV</CSVLink>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
<>
<div className="row g-3">
  <div className="col-md-5">
    <div className="card shadow-sm border-0">
      <div className="card-header bg-primary text-white">
        Date Range Filter
      </div>
      <div className="card-body">
        <div className="form-group">
          <label className="fw-semibold">Start Date:</label>
          <DatePicker className="form-control mt-2" selected={selectedStartDate} onChange={handleStartDateChange} />
        </div>
        <div className="form-group mt-3">
          <label className="fw-semibold">End Date:</label>
          <DatePicker className="form-control mt-2" selected={selectedEndDate} onChange={handleEndDateChange} />
        </div>
      </div>
    </div>
  </div>

  <div className="col-md-5 ms-md-5">
    <div className="card shadow-sm border-0">
      <div className="card-header bg-success text-white">
        Search Filter
      </div>
      <div className="card-body">
        <div className="form-group">
          <label className="fw-semibold">Search by Product:</label>
          <input className="form-control mt-2" type="text" value={productSearchTerm} onChange={handleProductSearchTermChange} />
        </div>
        <div className="form-group mt-3">
          <label className="fw-semibold">Search by Country:</label>
          <input className="form-control mt-2" type="text" value={countrySearchTerm} onChange={handleCountrySearchTermChange} />
        </div>
      </div>
    </div>
  </div>
</div>

<div className="row mt-5">
  <div className="col-12">
    <div className="card shadow-sm border-0">
      <div className="card-header bg-dark text-white">
        Data Table
      </div>
      <div className="card-body">
        <table className="table table-striped table-hover">
          <thead className="bg-light">
            <tr>
              <th onClick={() => handleSort('product')}>Product</th>
              <th onClick={() => handleSort('country')}>Country</th>
              <th onClick={() => handleSort('installations')}>Installations</th>
              <th onClick={() => handleSort('uninstallations')}>Uninstallations</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index}>
                <td>{item.product}</td>
                <td>{item.country}</td>
                <td>{item.installations}</td>
                <td>{item.uninstallations}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div className="row mt-4">
  <div className="col-12 d-flex justify-content-between">
    <div>
      <p className="fw-semibold">Total Installations: {totalInstallations}</p>
      <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
    </div>
    <div>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePagination(currentPage - 1)}>Previous</button>
          </li>
          <li className={`page-item`}>
            <button className="page-link" disabled>{currentPage}</button>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePagination(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>
    </div>
    <div>
      <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
    </div>
  </div>
</div>
</>
  );
};

export default Home;

