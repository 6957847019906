import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ListGroup from 'react-bootstrap/ListGroup';
import CountUp from 'react-countup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const Home = () => {
  const [installData, setInstallData] = useState([]);
  const [uninstallData, setUninstallData] = useState([]);
  const [dateRange, setDateRange] = useState('week');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [mostInstalledCountry, setMostInstalledCountry] = useState('');
  const [mostUninstalledCountry, setMostUninstalledCountry] = useState('');
  const [mostInstalledProduct, setMostInstalledProduct] = useState('');
  const [mostUninstalledProduct, setMostUninstalledProduct] = useState('');
  const [mostUsedBrowser, setMostUsedBrowser] = useState('');
  const [productInstallationData, setProductInstallationData] = useState([]);
  const [countryInstallationData, setCountryInstallationData] = useState([]);
  const [productUninstallationData, setProductUninstallationData] = useState([]);
  const [countryUninstallationData, setCountryUninstallationData] = useState([]);
  const [topProductsData, setTopProductsData] = useState([]);
    const [topUninstalledProductsData, setTopUninstalledProductsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  const fetchData = async () => {
    try {
      const installResponse = await axios.get('https://brdash.supportplus.app/install');
      const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
      // const installResponse = await axios.get('https://brdash.supportplus.app/m-install');
      // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');

      setInstallData(installResponse.data);
      // console.log("InstallResponse" , installResponse.data)
      setUninstallData(uninstallResponse.data);
      // console.log("UninstallResponse" , uninstallResponse.data)
      computeStatistics(installResponse.data, uninstallResponse.data);
      // console.log("installResponse :" , installResponse)
      // console.log("uninstallResponse :" , uninstallResponse)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

const filterDataByDateRange = (data, startDate, endDate) => {
    return data.filter(detail => {
        const detailDate = new Date(detail.datetime);
        // Convert detail date to UTC to align with API date
        const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
        return utcDetailDate >= startDate && utcDetailDate < endDate;
    }).length;
};

const generateData = () => {
    const getDateLabel = (date) => {
        const options = { month: 'short', weekday: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const data = [];
    const startDate = new Date(selectedDate);
    let endDate = new Date(selectedDate);

    switch (dateRange) {
        case 'day':
            endDate.setDate(endDate.getDate() + 1);
            break;
        case 'week':
            startDate.setDate(startDate.getDate() - 6);
            endDate.setDate(endDate.getDate() + 1);
            break;
        case 'month':
            startDate.setDate(1);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            endDate.setDate(endDate.getDate() + 1);
            break;
        case 'year':
            startDate.setMonth(0);
            startDate.setDate(1);
            endDate.setMonth(11);
            endDate.setDate(31);
            endDate.setDate(endDate.getDate() + 1);
            break;
        default:
            endDate.setDate(endDate.getDate() + 1);
            break;
    }

    // Convert start and end dates to UTC to handle time zone differences
    const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    for (let date = new Date(utcStartDate); date < utcEndDate; date.setDate(date.getDate() + 1)) {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);

        // Convert to UTC to handle time zone differences
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const utcNextDay = new Date(Date.UTC(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate()));

        const installations = filterDataByDateRange(installData, utcDate, utcNextDay);
        const uninstallations = filterDataByDateRange(uninstallData, utcDate, utcNextDay);
        const dateLabel = getDateLabel(utcDate);
        data.push({ date: dateLabel, installations, uninstallations });
    }

    return data;
};


  const computeStatistics = (installations, uninstallations) => {
        const installCountries = installations.map(install => install.cname);
        const uninstallCountries = uninstallations.map(uninstall => uninstall.cname);
        setMostInstalledCountry(getMostFrequentItem(installCountries));
        setMostUninstalledCountry(getMostFrequentItem(uninstallCountries));
    
        const installProducts = installations.map(install => install.pname);
        const uninstallProducts = uninstallations.map(uninstall => uninstall.pname);
        setMostInstalledProduct(getMostFrequentItem(installProducts));
        setMostUninstalledProduct(getMostFrequentItem(uninstallProducts));
    
        const installBrowsers = installations.map(install => install.m_Browser);
        setMostUsedBrowser(getMostFrequentItem(installBrowsers));
    
    // Compute product installation data
    const productInstallationMap = new Map();
    installations.forEach(install => {
      const productName = install.pname;
      productInstallationMap.set(productName, (productInstallationMap.get(productName) || 0) + 1);
    });
    setProductInstallationData(Array.from(productInstallationMap).map(([productName, install]) => ({ productName, install })));
    
    // Compute country installation data
    const countryInstallationMap = new Map();
    installations.forEach(install => {
      const countryName = install.cname;
      countryInstallationMap.set(countryName, (countryInstallationMap.get(countryName) || 0) + 1);
    });
    setCountryInstallationData(Array.from(countryInstallationMap).map(([countryName, install]) => ({ countryName, install })));
    
    // Compute product uninstallation data
    const productUninstallationMap = new Map();
    uninstallations.forEach(uninstall => {
      const productName = uninstall.pname;
      productUninstallationMap.set(productName, (productUninstallationMap.get(productName) || 0) + 1);
    });
    setProductUninstallationData(Array.from(productUninstallationMap).map(([productName, uninstall]) => ({ productName, uninstall })));
    
    // Compute country uninstallation data
    const countryUninstallationMap = new Map();
    uninstallations.forEach(uninstall => {
      const countryName = uninstall.cname;
      countryUninstallationMap.set(countryName, (countryUninstallationMap.get(countryName) || 0) + 1);
    });
    setCountryUninstallationData(Array.from(countryUninstallationMap).map(([countryName, uninstall]) => ({ countryName, uninstall })));
      }
        const getMostFrequentItem = (items) => {
        const countMap = new Map();
        items.forEach(item => {
          countMap.set(item, (countMap.get(item) || 0) + 1);
        });
        const sortedItems = Array.from(countMap.entries()).sort((a, b) => b[1] - a[1]);
        return sortedItems[0][0];
      };
    

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const generateTopProductsData = () => {
        const productCountMap = new Map();
        
        // Count installations
        installData.forEach(install => {
          const productName = install.pname;
          productCountMap.set(productName, {
            installations: (productCountMap.get(productName)?.installations || 0) + 1,
            uninstallations: productCountMap.get(productName)?.uninstallations || 0
          });
        });
      
        // Count uninstallations
        uninstallData.forEach(uninstall => {
          const productName = uninstall.pname;
          productCountMap.set(productName, {
            installations: productCountMap.get(productName)?.installations || 0,
            uninstallations: (productCountMap.get(productName)?.uninstallations || 0) + 1
          });
        });
      
        // Sort by total installations (installations - uninstallations)
        const sortedProducts = Array.from(productCountMap.entries()).sort((a, b) => {
          const totalA = a[1].installations - a[1].uninstallations;
          const totalB = b[1].installations - b[1].uninstallations;
          return totalB - totalA;
        }).slice(0, 10);
      
        return sortedProducts.map(product => ({
          name: product[0],
          installations: product[1].installations,
          uninstallations: product[1].uninstallations
        }));
      };
      
      useEffect(() => {
        const topProductsData = generateTopProductsData();
        setTopProductsData(topProductsData);
      }, [installData, uninstallData]);
      
      const generateTopUninstalledProductsData = () => {
        const productCountMap = new Map();
    
        // Count installations
        installData.forEach(install => {
          const productName = install.pname;
          productCountMap.set(productName, {
            installations: (productCountMap.get(productName)?.installations || 0) + 1,
            uninstallations: productCountMap.get(productName)?.uninstallations || 0
          });
        });
    
        // Count uninstallations
        uninstallData.forEach(uninstall => {
          const productName = uninstall.pname;
          productCountMap.set(productName, {
            installations: productCountMap.get(productName)?.installations || 0,
            uninstallations: (productCountMap.get(productName)?.uninstallations || 0) + 1
          });
        });
    
        // Sort by uninstallations
        const sortedProducts = Array.from(productCountMap.entries()).sort((a, b) => b[1].uninstallations - a[1].uninstallations).slice(0, 10);
    
        return sortedProducts.map(product => ({
          name: product[0],
          installations: product[1].installations,
          uninstallations: product[1].uninstallations
        }));
      };
    
      useEffect(() => {
        const topUninstalledProducts = generateTopUninstalledProductsData();
        setTopUninstalledProductsData(topUninstalledProducts);
      }, [installData, uninstallData]);

  return (
    <>
  
        <div className='row'>
        <div className='col-lg-12 mt-2'>
        <div className='text-center p-2 card shadow  bg-white' style={{width:'16rem'}}>
          <label>
            Select Date Range:
           
            <select className='ms-2' value={dateRange} onChange={handleDateRangeChange}>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
            </label>
          </div>
        </div>
      <p className='h3 fw-bold mt-5'>Total</p>
        <div className='col-lg-2'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold mb-0 small'>Installed</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={generateData() ? installData.length :'installations'} 
                duration={1} 
                separator="," />
              </p>
             </div>
             </div>
             </div>
        <div className='col-lg-2'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold mb-0 small'>Uninstalled</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={uninstallData.length} 
                duration={1} 
                separator="," />
              </p>
             </div>
             </div>
             </div>
        <div className='col-lg-2'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold mb-0 small'>Product Wise Installed</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={productInstallationData.length} 
                duration={1} 
                separator="," />
              </p>
             </div>
             </div>
             </div>
        <div className='col-lg-2'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold mb-0 small '>Product Wise Uninstall</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={productUninstallationData.length} 
                duration={1} 
                separator="," />
              </p>
             </div>
             </div>
             </div>
        <div className='col-lg-2'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold mb-0 small'>Country Wise Installed</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={countryInstallationData.length} 
                duration={1} 
                separator="," />
              </p>
             </div>
             </div>
             </div>
        <div className='col-lg-2'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold small mb-0 small'>Country Wise Uninstall</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={countryUninstallationData.length} 
                duration={1} 
                separator="," />
              </p>
             </div>
             </div>
             </div>


      {/* <p className='h3 fw-bold mt-5'>Top Most Installed</p>
        <div className='col-lg-4 '>
            <div className='card shadow  bg-white h-100'>
             <div className='card-body'>
                <p className='fw-bold mb-0'>Product : {mostInstalledProduct}</p>
             
             </div>
             </div>
             </div>
        <div className='col-lg-4'>
            <div className='card shadow  bg-white h-100'>
             <div className='card-body'>
              <p className='fw-bold mb-0 '>Country : {mostInstalledCountry}</p>
             
             </div>
             </div>
             </div>
        <div className='col-lg-4'>
            <div className='card shadow  bg-white h-100'>
             <div className='card-body'>
             
             
             </div>
             </div>
             </div>
        
          
      <p className='h3 fw-bold mt-5'>Top Most Uninstalled</p>
        <div className='col-lg-4 '>
            <div className='card shadow  bg-white h-100'>
             <div className='card-body'>
             <p className='fw-bold'>Product :{mostUninstalledProduct}</p>
           
             
             </div>
             </div>
             </div>
        <div className='col-lg-4'>
            <div className='card shadow  bg-white h-100'>
             <div className='card-body'>
             <p className='fw-bold'>Country : {mostUninstalledCountry}</p>
  
             
             </div>
             </div>
             </div>
        <div className='col-lg-4'>
            <div className='card shadow  bg-white h-100'>
             <div className='card-body'>
          
             </div>
             </div>
             </div> */}
        
  <div className='col-lg-12'>
<div className='row'>
  

            <div className='col-lg-6 mt-5'>
            <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
            <ResponsiveContainer width="100%" height={350}>
          {/*  line chart */}
          <LineChart
            data={generateData()}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="installations" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="uninstallations" stroke="#82ca9d" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
        </div>
            </div>

            <div className='col-lg-6 mt-5'>
            <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
            <ResponsiveContainer width="100%" height={350}>
   <BarChart
    data={generateData()}
    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="installations" fill="#8884d8" />
    <Bar dataKey="uninstallations" fill="#82ca9d" />
  </BarChart>
</ResponsiveContainer>
</div>
            </div>

            {/* <div className='col-lg-2 mt-5'>
            <div class="card mt-2" style={{height:'150px' , width:'18rem'}}>
             <div class="card-body">
             <p>Most Installed Product</p>
            <p className='fw-bold'>{mostInstalledProduct}</p>
           </div>
            </div>

            <div class="card mt-5" style={{height:'150px' , width:'18rem'}}>
             <div class="card-body">
              <p>Most Uninstalled Product</p>
                <p className='fw-bold'>{mostUninstalledProduct}</p>
                </div>
                </div>
            </div> */}

        <div className='col-lg-6 mt-5'>
        <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
        <ResponsiveContainer width="100%" height={350}>
          {/*  countryInstallationData bar chart */}
          <BarChart
             data={countryInstallationData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="countryName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="install" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        </div>
        <p className='fw-bold text-center'>Country Wise Installation Data</p>
        </div>
      
<div className='col-lg-6 mt-5'>
    <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
    <ResponsiveContainer width="100%" height={350}>
          {/* productUninstallationData bar chart */}
          <BarChart
             data={countryUninstallationData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="countryName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="uninstall" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        </div>
        <p className='fw-bold text-center'>Country Wise Uninstallation Data</p>
        </div>
        {/* <div className='col-lg-2 mt-5'>
<div class="card mt-3" style={{height:'150px' , width:'18rem'}}>
  <div class="card-body">
 
         
                <p>Most Installed Country</p>
                <p className='fw-bold'>{mostInstalledCountry}</p>
                
  </div>
</div>
            <div class="card mt-5" style={{height:'150px' , width:'18rem'}}>
  <div class="card-body">
  <p>Most Uninstalled Country</p>
                <p className='fw-bold'>{mostUninstalledCountry}</p>
  </div>
</div>
            </div> */}



                 
<div className='col-lg-6 mt-5'>
    <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
        <ResponsiveContainer width="100%" height={350}>
          {/* productInstallationData  bar chart */}
          <BarChart
            data={productInstallationData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="productName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="install" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        </div>
        <p className='fw-bold text-center'>Product Wise Installation Data</p>
        </div>
                 
<div className='col-lg-6 mt-5'>
    <div class="card shadow  bg-white" style={{height:'370px' , width:''}}>
        <ResponsiveContainer width="100%" height={350}>
          {/* productInstallationData  bar chart */}
          <BarChart
         data={productUninstallationData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="productName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="uninstall" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        </div>
        <p className='fw-bold text-center'>Product Wise Uninstallation Data</p>
        </div>

        {/* <div className='col-lg-2 mt-5'>
<div class="card mt-3 text-wrap" style={{height:'340px' , width:'18rem'}}>
  <div class="card-body">
                <p>Most Used Browser</p>
                <p className='fw-bold wrap'>{mostUsedBrowser}</p>
                
  </div>
</div>
            
            </div> */}

            <div className='col-lg-6 mt-5'>
             <div class="card shadow  bg-white h-100">
               <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  data={topProductsData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="installations" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <p className='fw-bold text-center'>Top 10 Most Installed Products</p>
          </div>


  <div className='col-lg-6 mt-5'>
  <div class="card shadow  bg-white h-100">
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        data={topUninstalledProductsData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="uninstallations" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  </div>
  <p className='fw-bold text-center'>Top 10 Most Uninstalled Products</p>
</div>
</div>
</div>


{/* Top Products */}
 <div className='row mt-5 pt-5'>
   <div className='col-lg-6'>
     <div className='card shadow  bg-white'>
       {/* <h6>Top Products Installed</h6> */}
       <table className='table table-striped'>
        <thead>
          <tr>
            <th> <h6>Top 10 Products Installed</h6></th>
            <th> <h6>Installed</h6></th>
            <th> <h6>Uninstalled</h6></th>
          </tr>
        </thead>
       <tbody>
        {topProductsData.map((product, index) => (
       <tr >
          <td key={index} className=''>
            {product.name}
            </td>
            <td>

         
            <button className='badge bg-primary '><span>{product.installations}</span></button>
            </td>
            <td>
            <button className='badge bg-danger  '>{product.uninstallations && <span > {product.uninstallations}</span>}</button>
          </td>
      </tr>
        ))}
      </tbody>
      </table>
    </div>
  </div>

  {/* Top Uninstalled Products */}
    <div className='col-lg-6'>
     <div className='card shadow  bg-white'>
       <table className='table table-striped'>
        <thead>
          <tr>
            <th> <h6>Top 10 Products Uninstalled</h6></th>
            <th> <h6>Uninstalled</h6></th>
            <th> <h6>Installed</h6></th>
          </tr>
        </thead>
       <tbody>
        {topUninstalledProductsData.map((product, index) => (
       <tr >
          <td key={index} className=''>
            {product.name}
            </td>
            <td>

            <button className='badge bg-danger'> <span >{product.uninstallations}</span></button> 
            </td>
            <td>
            <button className='badge bg-primary'>{product.installations && <span >{product.installations}</span>}</button>  
          </td>
      </tr>
        ))}
      </tbody>
      </table>
    </div>
  </div>
</div>



        </div>
   

    

    </>
  );
};

export default Home;
