import React, { useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { login } = useAuth();
  const Navigate = useNavigate();

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => { 
    e.preventDefault();
    try {
      // const response = await axios.post('http://localhost:3001/login', formData);
      const response = await axios.post('https://brdash.supportplus.app/login', formData);
      // console.log('Login successful:', response.data);
      login(); 
      Navigate('/home'); 
      toast('Login Succesfully');
    } catch (error) {
      console.error('Login failed:', error.response.data);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    Navigate('/');
  };

  return (
    <div className='container mt-5 py-5'>
      <div className='row'>
        <div className='col-md-12 lo'>
          <div className="loginMenu">
            <div className="form">
              <h4>LOGIN</h4>
              <form method='post' onSubmit={handleSubmit} className="loginForm">
                <input type="name" name="username"
                  value={formData.username}
                  autoFocus
                  onChange={handleChange}
                  placeholder="Username"
                  required
                />
                <input type="password" name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
                <button className='btn btn-info' type="submit">LOGIN</button>
                <ToastContainer />
              </form>
              {isLoggedIn && (
                <button className='btn btn-danger' onClick={handleLogout}>Logout</button>
                
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
