import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Installdata from './Components/Install/InstallData';
import Uninstalldata from './Components/Uninstall/UninstallData';
import Navbar from './Header/Navbar';
import Login from './Auth/Login';
import Register from './Auth/Signup';
import Home from './Components/Home/Home';
import List from './Components/List/List';
import Analytics from './Components/List/Ayanltics';
import Sidebar from './Components/sidebar/SideBar'; 

const App = () => {
  return (
  
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 px-0" >
            <div className='position-fixed'>

            <Sidebar /> 
            </div>
          </div>
          <div className="col-10 ">
              <>
                {/* <Navbar /> */}
              </>
              <Routes>
                <Route path="/install" element={<Installdata />} />
                <Route path="/uninstall" element={<Uninstalldata />} />
                {/* <Route path="/Graph" element={<Graph />} /> */}
                {/* <Route path="/product" element={<Productdata />} /> */}
                <Route path="/signup" element={<Register />} />
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Login />} />
                <Route path="/list" element={<List />} />
                <Route path="/analytics" element={<Analytics />} />
              </Routes>
            </div>
          </div>
        </div>
    
  );
};

export default App;
