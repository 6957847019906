import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, FormControl, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Flags from 'react-flags-select';
import { getOsIcon, getBrowserIcon, getCountryFlag } from '../../util/util.js';
import CountUp from 'react-countup';
import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const InstallData = () => {
  const [installDetails, setInstallDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [installData, setInstallData] = useState([]);
  const [productInstallationData, setProductInstallationData] = useState([]);
  const [uninstallData, setUninstallData] = useState([]);
  const [dateRange, setDateRange] = useState('week');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [filter, setFilter] = useState({
    countryName: '',
    os: '',
    city: '',
    productName: '',
  });
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filteredItemCount, setFilteredItemCount] = useState(0);

  useEffect(() => {
    fetchInstallDetails();
  }, []);

  useEffect(() => {
    calculateFilteredItemCount(); // Update filtered item count whenever filters change
  }, [filter]);

  useEffect(() => {
   fetchData();
  }, [dateRange , startDate , endDate]);

  const fetchInstallDetails = async () => {
    try {
      const response = await axios.get('https://brdash.supportplus.app/install');
      // const response = await axios.get('https://brdash.supportplus.app/m-install');
      // const response = await axios.get('https://dash.bitrecover.com/m-install');
      setInstallDetails(response.data);
    } catch (error) {
      console.error('Error fetching install details:', error);
    }
  };


  const fetchData = async () => {
    try {
      const installResponse = await axios.get('https://brdash.supportplus.app/install');
      const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
      // const installResponse = await axios.get('https://brdash.supportplus.app/m-install');
      // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');
      // const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
      // const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

      setInstallData(installResponse.data);
      setUninstallData(uninstallResponse.data);
      // computeStatistics(installResponse.data, uninstallResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const calculateFilteredItemCount = () => {
    const filteredItems = installDetails.filter(item => {
      return (
        (filter.countryName ? item.cname.toLowerCase().includes(filter.countryName.toLowerCase()) : true) &&
        (filter.productName ? item.pname.toLowerCase().includes(filter.productName.toLowerCase()) : true)
      );
    });
    setFilteredItemCount(filteredItems.length);
  };

  useEffect(() => {
    generateData();
  }, [dateRange, startDate, endDate]);


  
const filterDataByDateRange = (data, startDate, endDate) => {
  return data.filter(detail => {
      const detailDate = new Date(detail.datetime);
      // Convert detail date to UTC to align with API date
      const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
      return utcDetailDate >= startDate && utcDetailDate < endDate;
  }).length;
};


const generateData = () => {
  const getDateLabel = (date) => {
    const options = { month: 'short', weekday: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const data = [];
  let startUtcDate, endUtcDate;

  // Check if startDate and endDate are empty, if so, set a default time range
  if (!startDate || !endDate) {
    const defaultStartDate = new Date(); // Today's date
    defaultStartDate.setDate(defaultStartDate.getDate() - 6); // Go back 6 days
    startUtcDate = new Date(Date.UTC(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), defaultStartDate.getDate()));

    const defaultEndDate = new Date(); // Today's date
    defaultEndDate.setDate(defaultEndDate.getDate() + 1); // Go forward 1 day
    endUtcDate = new Date(Date.UTC(defaultEndDate.getFullYear(), defaultEndDate.getMonth(), defaultEndDate.getDate()));
  } else {
    startUtcDate = new Date(startDate);
    endUtcDate = new Date(endDate);
    endUtcDate.setDate(endUtcDate.getDate() + 1); // Increment end date by 1 day to include data for the end date
  }

  for (let date = new Date(startUtcDate); date < endUtcDate; date.setDate(date.getDate() + 1)) {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);

    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const utcNextDay = new Date(Date.UTC(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate()));

    const installations = filterDataByDateRange(installData, utcDate, utcNextDay);
    const dateLabel = getDateLabel(utcDate);
    const todayInstallations = filterDataByDateRange(installData, utcDate, new Date(utcDate.getTime() + (24 * 60 * 60 * 1000)));
    data.push({ date: dateLabel, installations, todayInstallations });
  }

  return data;
};

const computeStatistics = (installations, uninstallations) => {
  ;

// Compute product installation data
const productInstallationMap = new Map();
installations.forEach(install => {
const productName = install.pname;
productInstallationMap.set(productName, (productInstallationMap.get(productName) || 0) + 1);
});
setProductInstallationData(Array.from(productInstallationMap).map(([productName, install]) => ({ productName, install })));



// Compute country uninstallation data
}


const getMostFrequentItem = (items) => {
  const countMap = new Map();
  items.forEach(item => {
    countMap.set(item, (countMap.get(item) || 0) + 1);
  });
  const sortedItems = Array.from(countMap.entries()).sort((a, b) => b[1] - a[1]);
  return sortedItems[0][0];
};




const handleStartDateChange = e => {
  setStartDate(e.target.value);
};

const handleEndDateChange = e => {
  setEndDate(e.target.value);
};


  const handleInputChange = (event, key) => {
    setFilter({ ...filter, [key]: event.target.value });
  };

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const sortItems = (a, b) => {
    const valA = typeof a[sortColumn] === 'string' ? a[sortColumn].toLowerCase() : a[sortColumn];
    const valB = typeof b[sortColumn] === 'string' ? b[sortColumn].toLowerCase() : b[sortColumn];

    if (sortDirection === 'asc') {
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    } else {
      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    }
  };

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  

  const filteredItems = installDetails.filter((item) => {
    return (
      (filter.countryName ? item.cname.toLowerCase().includes(filter.countryName.toLowerCase()) : true) &&
      (filter.productName ? item.pname.toLowerCase().includes(filter.productName.toLowerCase()) : true)
    );
  });

  const sortedItems = sortColumn ? filteredItems.sort(sortItems) : filteredItems;

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const sortArrow = (columnName) => {
    if (sortColumn === columnName) {
      return sortDirection === 'asc' ? <span>&uarr;</span> : <span>&darr;</span>;
    }
    return null;
  };

  const csvData = filteredItems.map(detail => ({
    ID: detail.id,
    IP: detail.ipaddress,
    Product: detail.pname,
    CountryName: detail.cname,
    Date_Time: new Date(detail.datetime).toLocaleString('en-US', { timeZone: 'UTC' }),
  }));

  const installNumbers = () => {
    let totalInstallations = 0;

    // Convert start and end dates to UTC
    const startUtcDate = startDate ? new Date(startDate + 'T00:00:00Z') : null;
    const endUtcDate = endDate ? new Date(endDate + 'T23:59:59Z') : null;

    for (let detail of installData) {
        const detailDate = new Date(detail.datetime);

        // Adjust detail date to UTC
        const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));

        // Check if the detail date falls within the specified date range
        const isInRange = (!startUtcDate || utcDetailDate >= startUtcDate) &&
                          (!endUtcDate || utcDetailDate <= endUtcDate);

        if (isInRange) {
            totalInstallations++;
        }
    }

    // Format the total installations count
    const formattedInstallations = totalInstallations.toLocaleString('en-US');

    return formattedInstallations;
};

  const InstallCount = installNumbers();

  console.log(InstallCount);

  const getInstallationTrend = () => {
    const trendData = [];
    
    // Convert start and end dates to UTC
    const startUtcDate = startDate ? new Date(startDate + 'T00:00:00Z') : null;
    const endUtcDate = endDate ? new Date(endDate + 'T23:59:59Z') : null;

    let previousInstallations = 0;

    for (let detail of installData) {
        const detailDate = new Date(detail.m_DateTime);

        // Adjust detail date to UTC
        const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));

        // Check if the detail date falls within the specified date range
        const isInRange = (!startUtcDate || utcDetailDate >= startUtcDate) &&
                          (!endUtcDate || utcDetailDate <= endUtcDate);

        if (isInRange) {
            trendData.push({
                date: utcDetailDate,
                type: detail.m_InstallType 
            });

            previousInstallations++;
        }
    }

    // Calculate the trend
    let trend = '→'; // Default is no change
    const currentInstallations = trendData.length;
    if (currentInstallations > previousInstallations) {
        trend = '↑'; // Positive trend
    } else if (currentInstallations < previousInstallations) {
        trend = '↓'; // Negative trend
    }

    return { trendData, trend };
};



const Trends = getInstallationTrend();
  

  return (
    <div className="container-fluid mt-5">
      <div className='row'>
  {/* <h1 className="text-center mb-4">Install Details</h1> */}
  <div>
  <div className=''>
        <label>
          Start Date:
          <input 
          type="date" 
          value={startDate} 
          onChange={handleStartDateChange} />
        </label>
        <label>
          End Date:
          <input 
          type="date" 
          value={endDate} 
          onChange={handleEndDateChange} />
        </label>
      </div>

        </div>

        {/* <div className='col-lg-2 mt-5'>
            <div className='card shadow  bg-white'>
             <div className='card-body'>
              <p className='fw-bold small mb-0 small'>Installed</p>
              <p className='mb-0'>
                 <CountUp 
                start={0} 
                end={InstallCount.length} 
                duration={3} 
                separator="," />
              </p>
             </div>
             </div>
             </div> */}
        
        
             {/* <div className='col-lg-12'>
  
  <ResponsiveContainer width="100%" height={450}>
  <LineChart
  data={generateData()}
  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
  >
 <CartesianGrid strokeDasharray="3 3" />
 <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" 
            dataKey="installations" 
            stroke="#8884d8" 
            activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
        </div> */}

        <div className='col-lg-12 mt-5'>
            <div class='card shadow'>
            <ResponsiveContainer width="100%" height={450}>
   <BarChart
    data={generateData()}
    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="installations" fill="#8884d8" />
  </BarChart>
</ResponsiveContainer>
</div>
<p className='fw-bold text-center'> Installed Data</p>
            </div>



        



<div className='col-lg-3 mt-5'>
<DropdownButton
          title={`Show ${itemsPerPage} entries`}
          variant="primary"
          id="dropdown-basic-button"
        >
          <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
          <Dropdown.Item onClick={() => handleItemsPerPageChange(25)}>25</Dropdown.Item>
          <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>50</Dropdown.Item>
          <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>100</Dropdown.Item>
        </DropdownButton>
</div>
     
      <div className="col-lg-8 filters mb-3 ms-5 mt-5">
        <InputGroup>
      <div className="d-flex align-items-center">
          <span className="me-2">Filtered Item Count:</span>
          <span className="badge bg-primary me-5">{filteredItemCount}</span>
        </div>
          <FormControl
            placeholder="Country Name"
            value={filter.countryName}
            onChange={(e) => handleInputChange(e, 'countryName')}
            style={{borderRadius:"8px"}}
          />
          <FormControl
            placeholder="Product Name"
            value={filter.productName}
            onChange={(e) => handleInputChange(e, 'productName')}
            className='ms-4 '
            style={{borderRadius:"8px"}}
          />
        </InputGroup>
      </div>

      <div class="table-responsive">
        <Table bordered hover className="small mt">
          <thead className="">
            <tr className="text-center">
              <th onClick={() => handleSort('Id')}>ID {sortArrow('Id')}</th>
              <th onClick={() => handleSort('m_IpAddress')}>IP {sortArrow('ipaddress')}</th>
              <th onClick={() => handleSort('pname')}>Product-Name {sortArrow('pname')}</th>
              <th onClick={() => handleSort('cname')}>CountryName {sortArrow('cname')}</th>
              <th onClick={() => handleSort('datetime')}>Date - Time {sortArrow('datatime')}</th>
            </tr>
          </thead>
           <tbody className='small'>
         {currentItems.map((detail, index) => (
            <tr key={index}>
              <td>{detail.id}</td>
              <td>{detail.ipaddress}</td>
              <td>{detail.pname}</td>
              <td>
                {/* {detail.m_CountryCode && detail.cname && (
                  <img src={`flags/png`} alt={detail.cname} className="mr-1" />
                )} */}
                {detail.cname}
              </td>
              <td>{new Date(detail.datetime).toLocaleString('en-US', { timeZone: 'UTC' })}</td>
            </tr>
          ))}
        </tbody>
        </Table>
      </div>


      

      <div className="d-flex justify-content-between align-items-center mb-3">


        <div className="pagination">
          <Button
            variant="primary"
            className="mr-2"
            onClick={handlePreviousPage}
            disabled={currentPage <= 1}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Previous
          </Button>
          <span className="align-self-center mr-2">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="primary"
            onClick={handleNextPage}
            disabled={currentPage >= totalPages}
          >
            Next <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
        <div className="d-flex justify-content-end">
        <CSVLink data={csvData} filename={'filtered_install_details.csv'} className="btn btn-primary">
          Export to CSV
        </CSVLink>
      </div>
      </div>
     

      
      

      
      </div>
    </div>
  );
};

export default InstallData;























////////////////////////////////////////////////////////////////////




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import CountUp from 'react-countup'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
// // import '../graph.css';
// import {
//   LineChart,
//   BarChart,
//   Bar,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer
// } from 'recharts';

// const Home = () => {
//   const [installData, setInstallData] = useState([]);
//   const [uninstallData, setUninstallData] = useState([]);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [dateRange, setDateRange] = useState('week');
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [mostInstalledCountry, setMostInstalledCountry] = useState('');
//   const [mostUninstalledCountry, setMostUninstalledCountry] = useState('');
//   const [mostInstalledProduct, setMostInstalledProduct] = useState('');
//   const [mostUninstalledProduct, setMostUninstalledProduct] = useState('');
//   const [mostUsedBrowser, setMostUsedBrowser] = useState('');
//   const [productInstallationData, setProductInstallationData] = useState([]);
//   const [countryInstallationData, setCountryInstallationData] = useState([]);
//   const [productUninstallationData, setProductUninstallationData] = useState([]);
//   const [countryUninstallationData, setCountryUninstallationData] = useState([]);
//   const [topProductsData, setTopProductsData] = useState([]);
//   const [topUninstalledProductsData, setTopUninstalledProductsData] = useState([]);
//   const [showOptions, setShowOptions] = useState(false);

//   useEffect(() => {
//     fetchData();
//   }, [dateRange, startDate, endDate]);

//   const fetchData = async () => {
//     try {
//       const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
//       const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

//       setInstallData(installResponse.data);
//       setUninstallData(uninstallResponse.data);
//       computeStatistics(installResponse.data, uninstallResponse.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };


//   useEffect(() => {
//     generateData();
//   }, [dateRange, startDate, endDate]);


// const filterDataByDateRange = (data, startDate, endDate) => {
//     return data.filter(detail => {
//         const detailDate = new Date(detail.m_DateTime);
//         // Convert detail date to UTC to align with API date
//         const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
//         return utcDetailDate >= startDate && utcDetailDate < endDate;
//     }).length;
// };



// const generateData = () => {
//   const getDateLabel = (date) => {
//     const options = { month: 'short', weekday: 'short', day: 'numeric' };
//     return date.toLocaleDateString('en-US', options);
//   };

//   const data = [];
//   let startUtcDate, endUtcDate;

//   // Check if startDate and endDate are empty, if so, set a default time range
//   if (!startDate || !endDate) {
//     const defaultStartDate = new Date(); // Today's date
//     defaultStartDate.setDate(defaultStartDate.getDate() - 6); // Go back 6 days
//     startUtcDate = new Date(Date.UTC(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), defaultStartDate.getDate()));

//     const defaultEndDate = new Date(); // Today's date
//     defaultEndDate.setDate(defaultEndDate.getDate() + 1); // Go forward 1 day
//     endUtcDate = new Date(Date.UTC(defaultEndDate.getFullYear(), defaultEndDate.getMonth(), defaultEndDate.getDate()));
//   } else {
//     startUtcDate = new Date(startDate);
//     endUtcDate = new Date(endDate);
//     endUtcDate.setDate(endUtcDate.getDate() + 1); // Increment end date by 1 day to include data for the end date
//   }

//   for (let date = new Date(startUtcDate); date < endUtcDate; date.setDate(date.getDate() + 1)) {
//     const nextDay = new Date(date);
//     nextDay.setDate(nextDay.getDate() + 1);

//     const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
//     const utcNextDay = new Date(Date.UTC(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate()));

//     const installations = filterDataByDateRange(installData, utcDate, utcNextDay);
//     const uninstallations = filterDataByDateRange(uninstallData, utcDate, utcNextDay);
//     const dateLabel = getDateLabel(utcDate);
//     const tomorrowInstallations = filterDataByDateRange(installData, utcNextDay, new Date(utcNextDay.getTime() + (24 * 60 * 60 * 1000)));
//     let arrowDirection = '';
//     if (installations > 0 && tomorrowInstallations > 0) {
//       arrowDirection = installations > tomorrowInstallations ? 'up' : 'down';
//     } else {
//       arrowDirection = 'up'; 
//     }
//     data.push({ date: dateLabel, installations, uninstallations, arrowDirection });
//   }

//   return data;
// };

//   const handleStartDateChange = e => {
//     setStartDate(e.target.value);
// };

// const handleEndDateChange = e => {
//     setEndDate(e.target.value);
// };

//   const computeStatistics = (installations, uninstallations) => {
//     const installCountries = installations.map(install => install.m_CountryName);
//     const uninstallCountries = uninstallations.map(uninstall => uninstall.m_CountryName);
//     setMostInstalledCountry(getMostFrequentItem(installCountries));
//     setMostUninstalledCountry(getMostFrequentItem(uninstallCountries));

//     const installProducts = installations.map(install => install.pname);
//     const uninstallProducts = uninstallations.map(uninstall => uninstall.m_ProName);
//     setMostInstalledProduct(getMostFrequentItem(installProducts));
//     setMostUninstalledProduct(getMostFrequentItem(uninstallProducts));

//     const installBrowsers = installations.map(install => install.m_Browser);
//     setMostUsedBrowser(getMostFrequentItem(installBrowsers));

// // Compute product installation data
// const productInstallationMap = new Map();
// installations.forEach(install => {
//   const productName = install.m_ProName;
//   productInstallationMap.set(productName, (productInstallationMap.get(productName) || 0) + 1);
// });
// setProductInstallationData(Array.from(productInstallationMap).map(([productName, count]) => ({ productName, count })));

// // Compute country installation data
// const countryInstallationMap = new Map();
// installations.forEach(install => {
//   const countryName = install.m_CountryName;
//   countryInstallationMap.set(countryName, (countryInstallationMap.get(countryName) || 0) + 1);
// });
// setCountryInstallationData(Array.from(countryInstallationMap).map(([countryName, count]) => ({ countryName, count })));

// // Compute product uninstallation data
// const productUninstallationMap = new Map();
// uninstallations.forEach(uninstall => {
//   const productName = uninstall.m_ProName;
//   productUninstallationMap.set(productName, (productUninstallationMap.get(productName) || 0) + 1);
// });
// setProductUninstallationData(Array.from(productUninstallationMap).map(([productName, count]) => ({ productName, count })));

// // Compute country uninstallation data
// const countryUninstallationMap = new Map();
// uninstallations.forEach(uninstall => {
//   const countryName = uninstall.m_CountryName;
//   countryUninstallationMap.set(countryName, (countryUninstallationMap.get(countryName) || 0) + 1);
// });
// setCountryUninstallationData(Array.from(countryUninstallationMap).map(([countryName, count]) => ({ countryName, count })));
//   }

  
//   const getMostFrequentItem = (items) => {
//     const countMap = new Map();
//     items.forEach(item => {
//       countMap.set(item, (countMap.get(item) || 0) + 1);
//     });
//     const sortedItems = Array.from(countMap.entries()).sort((a, b) => b[1] - a[1]);
//     return sortedItems[0][0];
//   };

  
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };




//   const generateTopProductsData = () => {
//     const productCountMap = new Map();
    
//     // Count installations
//     installData.forEach(install => {
//       const productName = install.m_ProName;
//       productCountMap.set(productName, {
//         installations: (productCountMap.get(productName)?.installations || 0) + 1,
//         uninstallations: productCountMap.get(productName)?.uninstallations || 0
//       });
//     });
  
//     // Count uninstallations
//     uninstallData.forEach(uninstall => {
//       const productName = uninstall.m_ProName;
//       productCountMap.set(productName, {
//         installations: productCountMap.get(productName)?.installations || 0,
//         uninstallations: (productCountMap.get(productName)?.uninstallations || 0) + 1
//       });
//     });
  
//     // Sort by total installations (installations - uninstallations)
//     const sortedProducts = Array.from(productCountMap.entries()).sort((a, b) => {
//       const totalA = a[1].installations - a[1].uninstallations;
//       const totalB = b[1].installations - b[1].uninstallations;
//       return totalB - totalA;
//     }).slice(0, 10);
  
//     return sortedProducts.map(product => ({
//       name: product[0],
//       installations: product[1].installations,
//       uninstallations: product[1].uninstallations
//     }));
//   };
  
//   useEffect(() => {
//     const topProductsData = generateTopProductsData();
//     setTopProductsData(topProductsData);
//   }, [installData, uninstallData]);
  
//   const generateTopUninstalledProductsData = () => {
//     const productCountMap = new Map();

//     // Count installations
//     installData.forEach(install => {
//       const productName = install.m_ProName;
//       productCountMap.set(productName, {
//         installations: (productCountMap.get(productName)?.installations || 0) + 1,
//         uninstallations: productCountMap.get(productName)?.uninstallations || 0
//       });
//     });

//     // Count uninstallations
//     uninstallData.forEach(uninstall => {
//       const productName = uninstall.m_ProName;
//       productCountMap.set(productName, {
//         installations: productCountMap.get(productName)?.installations || 0,
//         uninstallations: (productCountMap.get(productName)?.uninstallations || 0) + 1
//       });
//     });

//     // Sort by uninstallations
//     const sortedProducts = Array.from(productCountMap.entries()).sort((a, b) => b[1].uninstallations - a[1].uninstallations).slice(0, 10);

//     return sortedProducts.map(product => ({
//       name: product[0],
//       installations: product[1].installations,
//       uninstallations: product[1].uninstallations
//     }));
//   };

//   useEffect(() => {
//     const topUninstalledProducts = generateTopUninstalledProductsData();
//     setTopUninstalledProductsData(topUninstalledProducts);
//   }, [installData, uninstallData]);


//   return (
//     <>
//     <div className='container'>
//         <div className='row'>
//         <div>
//           <div className='card' style={{ width: '18rem', marginTop: '1rem' }}>
//         <label>
//           Start Date:
//           <input 
//           type="date" 
//           value={startDate} 
//           onChange={handleStartDateChange} />
//         </label>
//         <label>
//           End Date:
//           <input 
//           type="date" 
//           value={endDate} 
//           onChange={handleEndDateChange} />
//         </label>
//       </div>

//         </div>


// <div className='col-lg-2'>
//             <div className='card'>
//               <h6>Total Installations</h6>
//               <p className='d flex'>
//                 <CountUp 
//                 start={0} 
//                 end={generateData() ? installData.length :'installations'} 
//                 duration={5} 
//                 separator="," />
//               </p>
//         {/* <div >

//              <LineChart width={100} height={100} data={generateData()}>
//             <Line type="monotone" dataKey="installations" stroke="#8884d8" strokeWidth={2} />
//             </LineChart>
//         </div> */}
//              </div>
//              </div>

// <div className='col-lg-2'>
// <div className='card'>
// <h6>Total Uninstall</h6>
// <p>
// <CountUp 
// start={0} 
// end={uninstallData.length} 
// duration={5} 
// separator="," />
// </p>
// </div>
// </div>

//    <div className='col-lg-2'>
//   <div className='card'>
//     <h6>Total Install p</h6>
//     <p>
//       <CountUp 
//       start={0} 
//       end={productInstallationData.length} 
//       duration={5} 
//       separator="," />
//     </p>
//   </div>
// </div>




// <div className='col-lg-2'>
//             <div className='card'>
//               <h6>Total Product un</h6>
//               <p>
//                 <CountUp 
//                 start={0} 
//                 end={productUninstallationData.length} 
//                 duration={5} 
//                 separator="," />
//               </p>
//             </div>
//           </div>


// <div className='col-lg-2'>
//             <div className='card'>
//               <h6>Total Country in</h6>
//               <p>
//                 <CountUp 
//                 start={0}
//                 end={countryInstallationData.length} 
//                 duration={5} 
//                 separator="," />
//               </p>
//             </div>
//           </div>


// <div className='col-lg-2'>
// <div className='card'>
//   <h6>Total Country un</h6>
//   <p>
//  <CountUp 
//   start={0} 
//   end={countryUninstallationData.length} 
//   duration={5}
//   separator="," />
//   </p>
//   </div>
//   </div>

//           {/*  line chart */}
//   <div className='col-lg-5'>
//   <div class="card" style={{height:'370px' , width:''}}>
//   <ResponsiveContainer width="100%" height={350}>
//   <LineChart
//   data={generateData()}
//   margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//   >
//  <CartesianGrid strokeDasharray="3 3" />
//  <XAxis dataKey="date" />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             <Line type="monotone" 
//             dataKey="installations" 
//             stroke="#8884d8" 
//             activeDot={{ r: 8 }} />
//             <Line type="monotone" 
//             dataKey="uninstallations" 
//             stroke="#82ca9d" 
//             activeDot={{ r: 8 }} />
//           </LineChart>
//         </ResponsiveContainer>
//         </div>
//         </div>

//   {/* insta and uninstall bar chart */}
//   <div className='col-lg-5'>
//   <div class="card" style={{height:'370px' , width:''}}>
//   <ResponsiveContainer width="100%" height={350}>
//    <BarChart
//     data={generateData()}
//     margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//   >
//     <CartesianGrid strokeDasharray="3 3" />
//     <XAxis dataKey="date" />
//     <YAxis />
//     <Tooltip />
//     <Legend />
//     <Bar dataKey="installations" fill="#8884d8" />
//     <Bar dataKey="uninstallations" fill="#82ca9d" />
//   </BarChart>
// </ResponsiveContainer>
// </div>
// </div>

// <div className='col-lg-2'>
// <div class="card mt-2" style={{height:'150px' , width:'18rem'}}>
//   <div class="card-body">
//   <p>Most Installed Product</p>
//   <p className='fw-bold'>{mostInstalledProduct}</p>
//   </div>
// </div>
//   <div class="card mt-5" style={{height:'150px' , width:'18rem'}}>
//   <div class="card-body">
//   <p>Most Uninstalled Product</p>
//   <p className='fw-bold'>{mostUninstalledProduct}</p>
//   </div>
// </div>
// </div>

//       {/* countryInstallationData bar chart */}
// <div className='col-lg-5'>
//   <div class="card" style={{height:'370px' , width:''}}>
//     <ResponsiveContainer width="100%" height={350}>
//       <BarChart
//         data={countryInstallationData}
//         margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="countryName" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Bar dataKey="count" fill="#8884d8" />
//       </BarChart>
//     </ResponsiveContainer>
//   </div>
//   <p className='fw-bold text-center'>countryInstallationData</p>
// </div>

//       {/* countryUninstallationData bar chart */}
// <div className='col-lg-5'>
//   <div class="card" style={{height:'370px' , width:''}}>
//     <ResponsiveContainer width="100%" height={350}>
//       <BarChart
//         data={countryUninstallationData}
//         margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="countryName" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Bar dataKey="count" fill="#8884d8" />
//       </BarChart>
//     </ResponsiveContainer>
//   </div>
//   <p className='fw-bold text-center'>countryUninstallationData</p>
// </div>

// <div className='col-lg-2'>
// <div class="card mt-3" style={{height:'150px' , width:'18rem'}}>
//   <div class="card-body">
//   <p>Most Installed Country</p>
//   <p className='fw-bold'>{mostInstalledCountry}</p>
                
//   </div>
// </div>
//   <div class="card mt-5" style={{height:'150px' , width:'18rem'}}>
//   <div class="card-body">
//   <p>Most Uninstalled Country</p>
//   <p className='fw-bold'>{mostUninstalledCountry}</p>
//   </div>
// </div>
// </div>



//       {/* productInstallationData bar chart */}
//   <div className='col-lg-5'>
//   <div class="card" style={{height:'370px' , width:''}}>
//     <ResponsiveContainer width="100%" height={350}>
//       <BarChart
//         data={productInstallationData}
//         margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="productName" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Bar dataKey="count" fill="#8884d8" />
//       </BarChart>
//     </ResponsiveContainer>
//   </div>
//   <p className='fw-bold text-center'>productInstallationData</p>
// </div>

//       {/* productUninstallationData bar chart */}
// <div className='col-lg-5'>
//   <div class="card" style={{height:'370px' , width:''}}>
//     <ResponsiveContainer width="100%" height={350}>
//       <BarChart
//         data={productUninstallationData}
//         margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="productName" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Bar dataKey="count" fill="#8884d8" />
//       </BarChart>
//     </ResponsiveContainer>
//   </div>
//   <p className='fw-bold text-center'>productUninstallationData</p>
// </div>

//  <div className='col-lg-2'>
// <div class="card mt-3" style={{height:'340px' , width:'18rem'}}>
// <div class="card-body">
//     <p>Most Used Browser</p>
//     <p className='fw-bold'>{mostUsedBrowser}</p>
                
//   </div>
// </div>
// </div>


//             <div className='col-lg-5'>
//             <div class="card" style={{ height: '370px', width: '' }}>
//               <ResponsiveContainer width="100%" height={350}>
//                 <BarChart
//                   data={topProductsData}
//                   margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//                 >
//                   <CartesianGrid strokeDasharray="3 3" />
//                   <XAxis dataKey="name" />
//                   <YAxis />
//                   <Tooltip />
//                   <Legend />
//                   <Bar dataKey="installations" fill="#8884d8" />
//                 </BarChart>
//               </ResponsiveContainer>
//             </div>
//             <p className='fw-bold text-center'>Top 10 Most Installed Products</p>
//           </div>
//           <div className='col-lg-5'>
//   <div class="card" style={{ height: '370px', width: '' }}>
//     <ResponsiveContainer width="100%" height={350}>
//       <BarChart
//         data={topUninstalledProductsData}
//         margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Bar dataKey="uninstallations" fill="#82ca9d" />
//       </BarChart>
//     </ResponsiveContainer>
//   </div>
//   <p className='fw-bold text-center'>Top 10 Most Uninstalled Products</p>
// </div>



// <div className='col-lg-12 card mt-5'>
//   <div className='row'>
  
// {/* Top Products */}
// <div className='row'>
//   <div className='col-lg-6'>
//     <div className='card'>
//       <h6>Top Products Installed</h6>
//       <ul className='list-group list-group-flush'>
//         {topProductsData.map((product, index) => (
//           <li key={index} className='list-group-item'>
//             {product.name}
//          <button className='badge bg-danger rounded-pill float-end'>Uninstalled:  {product.uninstallations && <span > {product.uninstallations}</span>}</button> 
//             <button className='badge bg-primary rounded-pill float-end'> Install: <span>{product.installations}</span></button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   </div>

//   {/* Top Uninstalled Products */}
//   <div className='col-lg-6'>
//     <div className='row'>
//       <div className='col-lg-12'>
//       <div className='card'>
//         <h6>Top Products Uninstalled</h6>
//           {topUninstalledProductsData.map((product, index) => (
//             <p key={index} className='list-group-item'>
//               {product.name}
//             <button className='badge bg-primary rounded-pill float-end'>Install:{product.installations && <span >{product.installations}</span>}</button>  
//             <button className='badge bg-danger rounded-pill float-end'> <span >Uninstalled :{product.uninstallations}</span></button> 
//             </p>
//           ))}
        
//         </div>
//       </div>
//       </div>
//     </div>
// </div>

//         </div>
//       </div>

//       </div>
//       </div>

//     </>
//   );
// };

// export default Home;


